<template>
  <div class="specialDialog">
    <!-- 创建 -->
    <el-dialog
      title="Add Special Promo"
      :visible.sync="dialogVisible"
      width="680px"
      @closed="dialogFormVisibleFn"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="150px"
        class="demo-ruleForm"
      >
        <!-- 选择图片位置 -->
        <el-form-item label="position" prop="positionIndex">
          <el-select
            v-model.trim="ruleForm.positionIndex"
            size="mini"
            placeholder="select serial number"
          >
            <el-option
              v-for="item in optionsIndex"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Enterprise Name">
          <template>
            <el-select v-model="enterpriseNameCodeAdd" size="mini" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseAddFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No" prop="contractNo">
          <el-input
            v-model.trim="ruleForm.contractNo"
            size="small"
            placeholder="Please enter"
          />
        </el-form-item>

        <el-form-item label="Image" prop="CodingImage">
          <el-upload
            ref="my-upload"
            :class="{ hideUploadEdit: showBtnDealImg }"
            :action="http"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="codeRemove"
            :on-change="codeChange"
            :on-error="errorImgFile"
            :before-upload="beforeAvatarUpload"
            :on-success="success"
            :file-list="flieList"
            :limit="linmitCode"
          >
            <i class="el-icon-plus" />
          </el-upload>
          <p class="centerText"><i class="el-icon-warning-outline" />  Size should be 200×430px</p>
        </el-form-item>
        <el-form-item label="link type" prop="recommendType">
          <el-radio-group
            v-model.trim="ruleForm.recommendType"
            size="mini"
            class="radio"
            @change="skuidChangeFn"
          >
            <el-radio label="1"> SKU ID </el-radio>
            <el-radio label="2"> category </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-show="category" label="category" prop="eleName">
          <el-input v-model.trim="ruleForm.eleName" size="small" disabled />
          <el-tree
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
          />
        </el-form-item>
        <el-form-item v-show="eleId" label="SKU ID" prop="eleId">
          <el-input
            v-model.trim="ruleForm.eleId"
            maxlength="10"
            size="small"
            oninput="value=value.replace(/\D/g,'')"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer specialPromoBtn">
        <el-button @click="cancelForm('ruleForm')">Cancel</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">Confirm</el-button>
      </div>
    </el-dialog>
    <!-- 缩略图 -->
    <el-dialog :visible.sync="dialogImageVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>

<script>
import { addContract } from '@/api/special/api';
import { getTree } from '@/api/ProductPool/api';
import {image_base_url} from '@/common/config/index.js'
import { getEnterpriseName } from '@/api/enterprise/enterprise';
export default {
  name: '',
  components: {},
  data() {
    var valiIcon = (rule, value, callback) => {
      // 图片验证
      var height = 430;
      var width = 200;
      let image = new Image();
      image.src = this.imgHeaderInit + self.ruleForm.imgUrl;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          callback(new Error('Please upload 200 × 430 pictures'));
        } else {
          callback();
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            callback(new Error('Please upload 200 × 430 pictures'));
          } else {
            callback();
          }
        };
      }
    };
    return {
      imgHeaderInit: image_base_url,
      // 控制上传显示隐藏
      showBtnDealImg: false,
      // dialog显示
      dialogVisible: false,
      dialogImageVisible: false,
      dialogImageUrl: '',
      // 添加弹框表单
      ruleForm: {
        positionIndex: null,
        contractNo: '',
        imgUrl: null,
        eleName: null,
        eleId: null,
        recommendType: ''
      },
      //   表单验证
      rules: {
        positionIndex: [
          { required: true, message: 'Choose to locate', trigger: 'change' }
        ],
        contractNo: [
          {
            required: true,
            message: 'Enter the Contract No',
            trigger: 'blur'
          },
          {
            min: 1,
            message: 'The length must be between 3 and 5 characters',
            trigger: 'blur'
          }
        ],
        CodingImage: [
          { required: true, message: 'upload pictures', trigger: 'change' }
        ],
        recommendType: [
          { required: true, message: 'SKU ID', trigger: 'change' }
        ],
        eleId: [
          {
            required: true,
            message: 'SKU ID',
            trigger: 'change'
          }
        ],
        eleName: [
          {
            required: true,
            message: 'Enter the category',
            trigger: 'blur'
          }
        ],
        imgUrl: [
          {
            required: true,
            message: 'Please upload pictures.',
            trigger: 'blur'
          },
          { required: true, validator: valiIcon, trigger: 'change' } // 图片验证
        ]
      },
      // 图片上传地址
      http: process.env.VUE_APP_BASE_API + '/system/ware/file/uploadImg',
      optionsIndex: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: '6',
          label: '6'
        },
        {
          value: '7',
          label: '7'
        },
        {
          value: '8',
          label: '8'
        },
        {
          value: '9',
          label: '9'
        },
        {
          value: '10',
          label: '10'
        }
      ],
      treeProps: {
        label: 'name',
        children: 'childlist',
        isLeaf: 'leaf'
      },
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      codeFile: '',
      codeFileList: '',
      hideCode: false,
      linmitCode: 1,
      eleId: false,
      category: false,
      data: [],
      flieList: [],
      EnterpriseList: [],
      enterpriseNameCodeAdd: ''
    };
  },
  computed: {},
  watch: {
    dialogVisible: async function() {
      await this.$nextTick();
      this.$refs['ruleForm'].resetFields();
    }
  },
  created() {
    this.getEnterpriseNameData();
  },
  mounted() {
    this.getTrees();
  },
  methods: {
    // 添加页面 切换企业名称，回显合同号
    changeEnterpriseAddFn(val) {
      this.ruleForm.contractNo = val
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    // 文件成功时
    success(response, file, fileList) {
      this.ruleForm.imgUrl = response.msg;
      this.checkoutImgFn()
    },
    errorImgFile(response) {
      this.ruleForm.imgUrl = '';
      this.flieList = [];
      this.codeRemove({},this.flieList)
      this.$message({ message: response, type: 'error' });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      if (!isJPG) {
        this.$message.error('Uploaded images can only be in JPG or PNG format!');
      }
      return isJPG;
    },
    // 检验图片的方法
    checkoutImgFn() {
      let than = this;
      var height = 430;
      var width = 200;
      let image = new Image();
      image.src = this.imgHeaderInit + this.ruleForm.imgUrl;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          than.$message.error('Please upload 200 × 430 pictures');
          than.ruleForm.imgUrl = '';
          than.flieList = [];
          than.codeRemove({},than.flieList)
          return;
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            than.$message.error('Please upload 200 × 430 pictures');
            than.ruleForm.imgUrl = '';
            than.flieList = [];
            than.codeRemove({},than.flieList)
            return;
          }
        };
      }
    },
    //查看缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    //提交验证调用添加接口
    submitForm(formName) {
      let than = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          delete than.ruleForm.CodingImage;
          addContract(than.ruleForm).then(res => {
            than.$parent.getlist();
            than.$refs[formName].resetFields();
            than.$refs['my-upload'].clearFiles();
            than.flieList = [];
            than.showBtnDealImg = than.flieList.length >= than.linmitCode;
            than.rules.CodingImage = [
              {
                required: true,
                message: 'upload pictures',
                trigger: 'change'
              }
            ];
            than.dialogVisible = false;
            than.eleId = false;
            than.category = false;
            than.ruleForm.recommendType = '';
            if (res.code == 200) {
              than.$message({
                message: 'Operation succeeded',
                type: 'success'
              });
            } else {
              than.$message({ message: 'The addition failed', type: 'error' });
              
            }
          });
        } else {
          return false;
        }
      });
    },
    // 取消的时候
    // 弹窗取消
    dialogFormVisibleFn() {
      this.eleId = false;
      this.category = false;
      this.dialogVisible = false;
      this.ruleForm.recommendType = '';
      this.enterpriseNameCodeAdd = '';
      this.flieList = [];
      this.showBtnDealImg = this.flieList.length >= this.linmitCode;
    },
    cancelForm(formName) {
      this.eleId = false;
      this.category = false;
      this.dialogVisible = false;
      this.ruleForm.recommendType = '';
      this.$refs[formName].resetFields();
      this.enterpriseNameCodeAdd = '';
      this.flieList = [];
      this.showBtnDealImg = this.flieList.length >= this.linmitCode;
    },
    // 图片上传时清楚校验规则
    codeChange(file, fileList) {
      this.codeFile = file;
      this.codeFileList = fileList;
      if (fileList.length == 1) {
        let { CodingImage, ...data } = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('CodingImage');
      this.showBtnDealImg = fileList.length >= this.linmitCode;
    },
    codeRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.CodingImage = [
          { required: true, message: 'upload pictures', trigger: 'change' }
        ];
      }
      this.showBtnDealImg = fileList.length >= this.linmitCode;
    },
    // 点击数返回的数据
    handleNodeClick(data) {
      this.ruleForm.eleId = data.id;
      this.ruleForm.cateLevel = data.level;
      this.ruleForm.eleName = data.name;
    },
    skuidChangeFn(val) {
      this.ruleForm.eleId = '';
      this.ruleForm.eleName = '';
      if (val == 1) {
        this.eleId = !this.eleId;
        this.category = false;
        delete this.rules.eleName;
      } else {
        this.category = !this.category;
        this.eleId = false;
        this.rules.eleName = [
          {
            required: true,
            message: 'Enter the category',
            trigger: 'blur'
          }
        ]
      }
    },
    getTrees() {
      let params = {
        loginUser: navigator.userAgent
      };
      getTree(params).then(res => {
        this.data = res.data;
      });
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
::v-deep .hideUploadEdit {
  .el-upload--picture-card {
    display: none;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    line-height: 146px;
    vertical-align: top;
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}

::v-deep .el-message-box {
  .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
}
.el-tree{
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
}
::v-deep .el-radio-group {
  height: 40px;
  display: flex;
  align-items: center;
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
.centerText {
  font-size: 14px;
  font-family: ArialMT;
  color: #9398A4;
}
.specialPromoBtn{
  .el-button{
    padding: 2px 0px;
  }
}
</style>
