import fetch from 'common/fetch';

// 特别促销合同号查询
export function queryingContract(params) {
  return fetch({
    url: '/system/product/precise',
    method: 'get',
    params
  });
}

// 创建web特别促销图片
export function addContract(data) {
  return fetch({
    url: '/system/product/add',
    method: 'post',
    data
  });
}

// 特别促销列表
export function getProductList(params) {
  return fetch({
    url: '/system/product/list',
    method: 'post',
    data: params
  });
}
// 删除图片
export function Delete(id) {
  return fetch({
    url: '/system/product/deleteId/' + id,
    method: 'delete'
  });
}
// 特别促销edit接口
export function Edit(data) {
  return fetch({
    url: '/system/product/edit',
    method: 'post',
    data
  });
}
//客户配置信息
//获取列表
export function customer(params) {
  return fetch({
    url: '/system/customer/list',
    method: 'post',
    data: params
  });
}
//添加
export function Add(data) {
  return fetch({
    url: '/system/customer/add',
    method: 'post',
    data
  });
}

// 删除
export function customerDelete(id) {
  return fetch({
    url: '/system/customer/deleteId/' + id,
    method: 'delete'
  });
}

// edit的接口
export function customerEitd(data) {
  return fetch({
    url: '/system/customer/update',
    method: 'post',
    data
  });
}
