<template>
  <div class="webSpecial">
    <div class="title">Special Promo settings</div>
    <!-- <span class="NumberSpan"> Contract No</span>
    <el-input
      v-model.trim="Contractinput"
      class="input"
      size="small"
      placeholder="Please enter Contract No"
    />

    <el-button class="specialReset" @click="specialReset">
      Reset
    </el-button>
    <el-button type="primary" class="specialSearch" @click="Search">
      Search
    </el-button> -->
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Contract No:" size="">
          <template>
            <el-input v-model.trim="Contractinput" placeholder="Pleas Enter">
              <el-button slot="append" @click="showSku">
                <i
                  class="el-icon-search"
                  style="margin-top:5px;margin-left:-5px"
                />
              </el-button>
            </el-input>
          </template>
        </el-form-item>
        <div class="btn_box_two">
          <el-button type="" @click="specialReset">Reset</el-button>
          <el-button type="primary" icon="el-icon-search" @click="SearchList">
            Search
          </el-button>
        </div>
      </el-form>
    </div>
    <!-- tabs -->
    <div class="specialTags">
      <template>
        <el-button
          type="primary"
          class="addButon el-icon-plus"
          @click="CreateProduct"
        >
          Add
        </el-button>
        <addDialog ref="childrenDom" />
        <el-tabs v-model.trim="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="Promotion List" name="second">
            <el-table
              :data="tableData"
              class="promotionList"
              :header-cell-style="{ background: '#e8f1fe' }"
              style="width: 100%"
            >
              <!-- 修改无数据时 提示文字 -->
              <template slot="empty" class="logo_no">
                <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
                <p style="color: #969799">No more data</p>
              </template>
              <el-table-column label="Position" width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.positionIndex || '--' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Contract No" min-width="210">
                <template slot-scope="scope">
                  <span
                    class="contractnoSpan"
                    style="cursor: pointer"
                    @click="contractNoClick(scope)"
                  >{{ scope.row.contractNo || '--' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Link Type" min-width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.recommendType == 1"> SKU </span>
                  <span v-if="scope.row.recommendType == 2"> Category </span>
                </template>
              </el-table-column>
              <el-table-column label="Category Name" min-width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.recommendType == 1 ? '--' : scope.row.eleName }}</span>
                </template>
              </el-table-column>
              <el-table-column label="SKU ID" min-width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.recommendType == 1 ? scope.row.eleId : '--' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Image" min-width="210">
                <template slot-scope="scope">
                  <img
                    :src="scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl"
                    width="96px"
                    style="cursor: pointer"
                    HEAD
                    @click="handlePreviews(scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl)"
                  >
                </template>
              </el-table-column>
              <el-table-column label="Operation" width="127" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="handleUpdate(scope.row)"
                  >
                    Edit
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="handleDel(scope.row)"
                  >
                    Delete
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pages">
              <el-pagination
                :current-page="pages.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="Preview" name="first">
            <br>
            <div class="tab__content">
              <div
                v-for="(item, index) in tableLists"
                :key="index"
                class="categoryList"
              >
                <img
                  v-for="(items, indexs) in item"
                  :key="indexs"
                  :src="items.imgUrl.indexOf('http') == -1 ? imgHeaderInit + items.imgUrl : items.imgUrl"
                  alt=""
                  class="categoryListDiv"
                >
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>
    <!-- 修改 -->
    <el-dialog
      title="Modification Banner"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="680px"
      @close="cancelForm()"
    >
      <span>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="150px"
          class="demo-ruleForm"
        >
          <!-- 选择图片位置 -->
          <el-form-item label="position" prop="positionIndex">
            <el-select
              v-model.trim="ruleForm.positionIndex"
              size="mini"
              placeholder="select serial number"
            >
              <el-option
                v-for="item in optionsIndex"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Contract No" prop="contractNo">
            <el-input
              v-model.trim="ruleForm.contractNo"
              size="small"
              placeholder="Please enter"
              :disabled="inputDisabled"
            />
          </el-form-item>
          <el-form-item label="Image" prop="imgUrl">
            <el-upload
              ref="my-upload"
              :class="{ hideUploadEdit: showBtnDealImg }"
              :action="http"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="codeRemove"
              :on-error="errorImgFile"
              :on-change="codeChange"
              :on-success="success"
              :file-list="fileList"
              :limit="linmitCode"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <p class="centerText">
              <i class="el-icon-warning-outline" /> Size should be 200×430px
            </p>
          </el-form-item>

          <el-form-item label="link type" prop="recommendType">
            <el-radio-group
              v-model.trim="ruleForm.recommendType"
              size="mini"
              @change="recommendType"
            >
              <el-radio :label="1"> SKUI ID </el-radio>
              <el-radio :label="2"> Category </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="showHideFlag === 1" label="SKU ID" prop="eleId">
            <el-input
              v-model.trim="ruleForm.eleId"
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d\,]/g,'')"
              @change="eleIdChange"
            />
          </el-form-item>
          <el-form-item
            v-show="showHideFlag === 2"
            label="category"
            prop="eleName"
            class="category"
          >
            <el-input v-model.trim="ruleForm.eleName" size="small" disabled />
            <el-tree
              ref="tree"
              node-key="id"
              :data="data"
              :props="defaultProps"
              :default-expanded-keys="defaultExpandedKeys"
              :default-checked-keys="defaultCheckedKeys"
              show-checkbox
              :expand-on-click-node="false"
              @check-change="handleNodeClick"
            />
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer specialPromoBtn">
        <el-button @click="cancelForm">Cancel</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">Confirm
        </el-button>
      </span>
    </el-dialog>
    <!-- 列表里面的缩略图 -->
    <el-dialog :visible.sync="dialogListVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageListUrl"
        alt=""
      >
    </el-dialog>
    <!-- 缩略图 -->
    <el-dialog :visible.sync="dialogImageVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryingContract, getProductList, Delete, Edit } from '@/api/special/api';
import { getTree } from '@/api/ProductPool/api';
import addDialog from './specialDialog.vue';
import Delotal from '../../contractManage/conttemplat.vue';
import {image_base_url} from '@/common/config/index.js'
export default {
  name: 'Special',
  components: {
    addDialog,
    Delotal
  },
  data() {
    return {
      emptyImgSrc: '',
      imgHeaderInit: image_base_url,
      // 控制上传显示隐藏
      showBtnDealImg: false,
      activeName: 'second',
      url: '',
      Contractinput: null,
      // dialog显示
      dialogVisible: false,
      dialogImageVisible: false,
      dialogImageUrl: '',
      // 列表dialog显示
      dialogListVisible: false,
      // 列表dialog图片地址
      dialogImageListUrl: '',
      // 第一个tab的表格数据
      tableLists: [],
      //loding
      loading: true,
      //分页信息
      pages: {
        pageSize: 10,
        page: 1,
        total: 0
      },
      // 点击数返回的数据
      treeFuncs: {
        name: '',
        level: ''
      },
      // 添加弹框表单
      ruleForm: {
        positionIndex: null,
        contractNo: '',
        imgUrl: null,
        recommendType: ''
      },
      //   表单验证
      rules: {
        positionIndex: [
          { required: true, message: 'Choose to locate', trigger: 'change' }
        ],
        contractNo: [
          {
            required: true,
            message: 'Enter the contract number',
            trigger: 'blur'
          },
          {
            min: 1,
            message: 'The length must be between 3 and 5 characters',
            trigger: 'blur'
          }
        ],
        recommendType: [
          { required: true, message: 'selective type ', trigger: 'change' }
        ],
        eleName: [
          {
            required: true,
            message: 'Enter the category',
            trigger: 'blur'
          }
        ],
        eleId: [
          {
            required: true,
            message: 'selective type ',
            trigger: 'change'
          }
        ]
      },
      // 图片上传地址
      http: process.env.VUE_APP_BASE_API + '/system/ware/file/uploadImg',
      // 修改的时候回显图片
      fileList: [],
      dialogimgVisible: false,
      disabled: false,
      //   特别促销列表数据
      tableData: [],
      value: 'Contract No',
      optionsIndex: [
        {
          value: '1',
          label: '1'
        },
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: '6',
          label: '6'
        },
        {
          value: '7',
          label: '7'
        },
        {
          value: '8',
          label: '8'
        },
      ],
      Image: null,
      trues: true,
      treeProps: {
        label: 'name',
        children: 'childlist',
        isLeaf: 'leaf'
      },
      data: [],
      defaultExpandedKeys: [],
      defaultCheckedKeys: [],
      // 切换标识  1 显示 id， 2 显示树结构
      showHideFlag: '',

      inputDisabled: false,

      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      codeFile: '',
      codeFileList: '',
      hideCode: false,
      linmitCode: 1,
      dialogSKU: false,
      multipleSelections: ''
      // 这里存放要默认展开的节点 id
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getlist();
  },
  methods: {
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateDatas() {
      this.Contractinput = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    // 点击重置时
    specialReset() {
      this.Contractinput = null;
      this.getlist();
    },
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.getlist();
      } else {
        tab.index == 0
        this.tableLists = [];
        if (this.Contractinput == null) {
          return false;
        }
        return this.$message({message:'Please click thhe contractNo to preview'})
        
      }
    },
    // 点击创建出现弹窗
    CreateProduct() {
      this.$refs.childrenDom.dialogVisible = true;
    },
    // 点击搜素的时候
    SearchList() {
      this.getlist();
    },
    SearchContractNoFn() {
      queryingContract(
        Object.assign({
          pageNo: this.pages.page,
          pageSize: this.pages.pageSize,
          contractNo: this.Contractinput
        })
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableLists = res.data.dataList;
          const newDataList = [];
          let current = 0;
          if (this.tableLists && this.tableLists.length > 0) {
            for (let i = 0; i <= this.tableLists.length - 1; i += 1) {
              if (i % 5 !== 0 || i === 0) {
                if (!newDataList[current]) {
                  newDataList.push([this.tableLists[i]]);
                } else {
                  newDataList[current].push(this.tableLists[i]);
                }
              } else {
                current += 1;
                newDataList.push([this.tableLists[i]]);
              }
            }
          }
          this.tableLists = [...newDataList];
          this.tableData = res.data.dataList;
          this.pages.total = res.data.total;
          this.$message({
            message: 'Search succeeded',
            type: 'success'
          });
        } else {
          this.tableData = [];
          this.pages.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 文件成功时
    success(response, file, fileList) {
      this.ruleForm.imgUrl = response.msg;
      this.checkoutImgFn()
    },
    // 检验图片的方法
    checkoutImgFn() {
      let than = this;
      var height = 430;
      var width = 200;
      let image = new Image();
      image.src = this.imgHeaderInit + this.ruleForm.imgUrl;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          than.$message.error('Please upload 200 × 430 pictures');
          than.ruleForm.imgUrl = '';
          than.flieList = [];
          than.codeRemove({},than.flieList)
          return;
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            than.$message.error('Please upload 200 × 430 pictures');
            than.ruleForm.imgUrl = '';
            than.flieList = [];
            than.codeRemove({},than.flieList)
            return;
          }
        };
      }
    },
    errorImgFile(response) {
      this.ruleForm.imgUrl = '';
      this.flieList = [];
      this.codeRemove({},this.flieList)
      this.$message({ message: response, type: 'error' });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      if (!isJPG) {
        this.$message.error('Uploaded images can only be in JPG or PNG format!');
      }
      return isJPG;
    },
    handleRemove(file) {},
    //查看缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.fileList = [];
    },
    // 选中的节点
    //提交验证调用修改接口
    submitForm(formName) {
      let { positionIndex, contractNo, imgUrl, recommendType, eleId, id } = this.ruleForm;
      let than = this;
      if (than.ruleForm.recommendType === 1) {
        delete this.rules.eleName;
      } else {
        this.rules.eleName = [
          {
            required: true,
            message: 'Enter the category',
            trigger: 'blur'
          }
        ]
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          let num = Object.assign({
            positionIndex,
            contractNo,
            imgUrl,
            recommendType,
            eleId,
            eleName: than.ruleForm.recommendType == 1 ? '' : than.treeFuncs.name,
            cateLevel: than.ruleForm.recommendType == 1 ? '' : than.treeFuncs.level,
            id
          });
          Edit(num).then(res => {
            if (res.code == 200 && res.success) {
              this.$refs[formName].resetFields();
              this.dialogVisible = false;
              this.getlist();
            } else {
              this.$message.error(res.msg);
            }
          });
          this.fileList = [];
        } else {
          return false;
        }
      });
    },
    // 点击取消
    cancelForm() {
      this.dialogVisible = false;
      this.showHideFlag = ''
      this.fileList = [];
    },
    //分页改变
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.pages.page = val;
      this.getlist();
    },
    // 获取数据
    getlist() {
      getProductList(
        Object.assign({
          pageNo: this.pages.page,
          pageSize: this.pages.pageSize,
          contractNo: this.Contractinput
        })
      )
        .then(res => {
          if (res.success && res.code === 200) {
            this.tableData = res.data.dataList;
            this.pages.total = res.data.totalCount;
          } else {
            this.tableData = [];
            this.pages.total = 0;
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
        .catch(() => {
        });
    },
    handlePreview(file) {
      this.dialogImageUrl = file;
      this.dialogimgImage = true;
    },
    handlePreviews(flie) {
      this.dialogListVisible = true;
      this.dialogImageListUrl = flie;
    },
    handleDel(row) {
      this.$confirm(
        'This operation will permanently delete the file. Do you want to continue?',
        {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel ',
          type: 'warning'
        }
      )
        .then(async () => {
          await Delete(row.id)
            .then(res => {
              this.$message({
                message: 'Delete succeeded',
                type: 'success'
              });
              this.getlist();
            })
            .catch(error => {
              this.$message({
                message: error.msg,
                type: 'error'
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancelled delete'
          });
        });
    },
    handleUpdate(row) {
      this.fileList = [];
      this.getTrees();
      this.trues = false;
      // this.showBtnDealImg = true;
      // this.showBtnDealImg = true;
      this.ruleForm.recommendType = row.recommendType;
      // 深拷贝一下不让列表数据跟着改变
      const datas = JSON.parse(JSON.stringify(row));
      if (row.imgUrl.indexOf('http') == -1) {
        this.fileList.push({ url: this.imgHeaderInit + row.imgUrl });
      } else {
        this.fileList.push({ url: row.imgUrl });
      }
      this.ruleForm = datas;
      if (this.ruleForm.recommendType == 1) {
        this.showHideFlag = 1;
        this.treeFuncs.name = '';
        this.treeFuncs.level = '';
        this.defaultCheckedKeys = [];
        this.defaultExpandedKeys = [];
      } else if (this.ruleForm.recommendType == 2) {
        this.showHideFlag = 2;
        this.treeFuncs.name = datas.eleName;
        this.treeFuncs.level = datas.cateLevel;
        this.defaultCheckedKeys = [datas.eleId];
        // this.ruleForm.eleId = '';
        // this.ruleForm.eleName = '';
      }  else {
        this.showHideFlag = '';
      }
      this.showBtnDealImg = this.fileList.length >= this.linmitCode;
      this.dialogVisible = true;
      this.inputDisabled = true;
    },
    // 获取展示树的层级
    getTreeOperId(data) {
      if (data.id === this.ruleForm.eleId) {
        this.defaultExpandedKeys = [data.pid];
      } else {
        if (data.childList && data.childList.length > 0) {
          data.childList.forEach((p1,p2) => {
            this.getTreeOperId(p1);
          })
        } else {
          return;
        }
      }
    },
    // 获取类目
    getTrees() {
      let params = {
        loginUser: navigator.userAgent
      };
      getTree(params).then(res => {
        if (res.code === 200) {
          this.data = res.data;
          // 处理编辑时 树反选打开
          this.data.forEach((item,index) => {
            this.getTreeOperId(item);
          });
        } else {
          this.data = [];
        }
      });
    },
    // 点击tree树返回的数据
    handleNodeClick(data, checked) {
      if (checked) {
        this.treeFuncs = data;
        this.ruleForm.eleName = data.name
        this.$refs.tree.setCheckedKeys([data.id], true);
      }
    },
    // 选项卡切换
    recommendType(val) {
      this.showHideFlag = val;
      if (val === 1) {
        delete this.rules.eleName;
        this.ruleForm.eleId = '';
      } else {
        this.rules.eleName = [
          {
            required: true,
            message: 'Enter the category',
            trigger: 'blur'
          }
        ]
      }
    },
    // 当用户打开需改列表的时候输入改变skuid的时候类目数据清空
    eleIdChange() {
      this.ruleForm.cateLevel = null;
      this.ruleForm.eleName = null;
    },
    // 图片上传时清楚校验规则
    codeChange(file, fileList) {
      this.codeFile = file;
      this.codeFileList = fileList;
      if (fileList.length == 1) {
        let { imgUrl, ...data } = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('imgUrl');
      this.showBtnDealImg = fileList.length >= this.linmitCode;
    },
    // 图片删除
    codeRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.imgUrl = [
          { required: true, message: 'upload pictures', trigger: 'change' }
        ];
      }
      this.showBtnDealImg = fileList.length >= this.linmitCode;
    },
    contractNoClick(row) {
      this.activeName = 'first';
      this.Contractinput = row.row.contractNo;
      this.pages.page = 1;
      this.SearchContractNoFn();
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.webSpecial {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;
  .promotionList {
    width: 100%;
    .contractnoSpan {
      color: #2262ff;
    }
  }
  .logo_no {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    height: 66px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    padding: 24px 16px;
    font-size: 16px;
    font-weight: Bold;
    line-height: 17px;
  }
  .serch_box {
    width: 100%;
    background: #fff;
    padding: 24px 16px 18px;
    .el-input {
      margin-right: 40px;
    }
    ::v-deep.el-input__inner {
      height: 32px;
    }
  }
  .fixed_form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      display: flex;
      //flex-direction: column;
      margin-bottom: 0;
      /deep/.el-form-item__label {
        text-align: left;
      }
      ::v-deep .el-form-item__content {
        display: flex;
        align-items: center;
      }
    }
  }

  ::v-deep .el-tabs__nav {
    .is-active {
      color: #2262ff;
    }
  }
  .NumberSpan {
    margin: 20px 0 0 15px;
  }
  .specialReset,
  .specialSearch {
    font-size: 14px;
    font-family: ArialMT;
    width: 100px;
    height: 32px;
  }
  .img_Div {
    float: left;
    width: 50%;
    // background: rgba(183, 188, 201, 0.2);
    border-radius: 2px 2px 0 0;
    padding: 20px 0 0 20px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .img_box {
    background: white;
    display: flex;
    width: 100%;
    justify-content: space-around;
    div {
      height: 428px;
    }
    div:nth-of-type(1) {
      width: 50%;
      height: 425px;
    }
    div:nth-of-type(2) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      div {
        width: 50%;
        height: 203px;
      }
    }
  }
  /deep/ .el-tabs__nav-wrap {
    width: 50% !important;
  }
  /deep/ .el-tabs__nav {
    margin: 0 0 0 16px;
  }
  .tages_options {
    width: 240px;
    margin-top: 34px;
  }
  .addButon {
    z-index: 99;
    width: 140px;
    height: 32px;
    padding: 0px;
    border-radius: 4px;
    right: 31px;
    position: absolute;
  }
  .pages {
    float: right;
    .el-pagination {
      margin: 10px 2px 0 0;
    }
  }
  .el-tree{
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
  }
  // .tableLists {
  //   float: right;
  //   width: 590px;
  //   margin-top: 20px;
  //   .tableListsDiv {
  //     float: left;
  //   }
  // }
  // .tableLists:nth-child(1) {
  //   margin-left: 10px;
  // }
  .tab__content {
    width: 1190px;
    .categoryList {
      float: right;
      width: 590px;
      margin-top: 20px;
      &:nth-child(2) {
        margin-left: 10px;
      }
      .categoryListDiv {
        width: 195px;
        height: 215px;
        transform: translateX(0px);
        transition: transform 0.5s;
        float: left;
        &:nth-child(1) {
          width: 200px;
          height: 430px;
        }
      }
    }

    .panel-body {
      padding: 0 16px;
    }
     .el-dialog__footer{
     .el-button {
        padding: 0;
        width: 100px;
        font-family: ArialMT;
        font-size: 14px;
      }
    }
    
    ::v-deep .el-table {
      .cell {
        white-space: pre-wrap;
        word-break: keep-all;
      }
    }

    ::v-deep .el-pager {
      li {
        border: 0;
      }
    }
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #2262ff;
      border-color: #2262ff;
    }
    ::v-deep .el-button--text {
      color: #2262ff;
      text-align: left;
      margin-left: 0;
    }
    ::v-deep .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
      .el-radio__label {
        color: #242526;
      }
      .is-checked {
        .el-radio__inner {
          background-color: #2262ff;
        }
        .el-radio__label {
          color: #242526;
        }
      }
    }
  }
  ::v-deep .hideUploadEdit {
    .el-upload--picture-card {
      display: none;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      line-height: 146px;
      vertical-align: top;
    }
  }
}
.btn_box_two {
  button {
    width: 100px;
    // padding: 0 12px !important;
  }
}
.specialPromoBtn{
  .el-button{
    padding: 2px 0px;
  }
}
</style>
<style lang="less">
.webSpecial {
  .input {
    margin: 24px 48px 0 16px;
  }
  .specialTags {
    margin: 32px 16px 0 16px;
  }
}
.centerText {
  font-size: 14px;
  font-family: ArialMT;
  color: #9398a4;
}
</style>
