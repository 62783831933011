import fetch from 'common/fetch';

// Commodity pools接口
export function getProductList(params) {
  return fetch({
    url: '/system/sku/pool/list',
    method: 'post',
    data: params
  });
}

// Commodity pools当前页数据全部导出
export function poolExports(params) {
  return fetch({
    url: '/system/sku/pool/export',
    method: 'post',
    data: params,
    responseType: 'blob'
  });
}
// Commodity pools每条数据全部导出
export function poolOneExports(params) {
  return fetch({
    url: `/system/pool/export?poolId=${params.id}`,
    method: 'get',
    data: params,
    responseType: 'blob'
  });
}

// 设置和上传添加Commodity pools三级类目
export function getCategoryTree(params) {
  return fetch({
    url: '/system/pool/getCategoryTree',
    method: 'post',
    params
  });
}
// cateIds=1,2,3,4&poolId=100000033
export function deleteCate(params) {
  return fetch({
    url: '/system/pool/deleteCate',
    method: 'delete',
    params
  });
}

export function updateCate(params) {
  return fetch({
    url: '/system/pool/updateCate',
    method: 'post',
    params
  });
}
// /system/info/getCategoryAndVOlist'
export function getTree(params) {
  return fetch({
    url: '/system/info/getCategoryAndParentVOList',
    method: 'get',
    params
  });
}
// 添加下载模板
export function download(params) {
  return fetch({
    url: '/system/ware/file/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob'
  });
}
// /system/sku/pool
// 提交添加验证的接口;
export function addPoolSkuIdss(data) {
  return fetch({
    url: '/system/sku/pool/addPoolSkuIds',
    method: 'post',
    data
  });
}
//批量删除
export function removePoolAndSkuRelations(data) {
  return fetch({
    url: '/system/sku/pool/removePoolAndSkuRelation',
    method: 'post',
    data
  });
}
// /system/pool/delete/100000042
// 单行Commodity pools的删除
export function DeleteSku(id) {
  return fetch({
    url: '/system/pool/delete/' + id,
    method: 'post'
  });
}
//Commodity pools结束
